<template>
    <c-panel>
        <c-grid class="widths-50-sm widths-33-md widths-25-lg widths-25-xl">
            <template v-for="artist in artists" :key="artist.id">
                <c-grid-item v-if="artist.data?.profilePic" class="flex f-align-stretch">
                    <c-card @click="$router.push('/admin/artist/' + artist.id)">
                        <template #image>
                            <c-image :image="artist.data.profilePic" />
                        </template>
                        <template #title>{{ artist.data.name }}</template>
                    </c-card>
                </c-grid-item>
            </template>
        </c-grid>
        <c-button class="mt-5" @click="$router.push('/admin/artist')">
            <template #icon>
                <i class="fas fa-plus"></i>
            </template>
            New Artist
        </c-button>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { Database } from "vuebase";
import { Artist } from "../main";

export default defineComponent({
    name: "Artists",
    setup() {
        const database = inject(Database.InjectionKey);
        const artists = database?.collection<Artist>("artists").documents();

        return {
            artists,
        };
    },
});
</script>
