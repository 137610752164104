
import { defineComponent, inject } from "vue";
import { Database } from "vuebase";
import { Artist } from "../main";

export default defineComponent({
    name: "Artists",
    setup() {
        const database = inject(Database.InjectionKey);
        const artists = database?.collection<Artist>("artists").documents();

        return {
            artists,
        };
    },
});
